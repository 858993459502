import projectsModule from '@modules/projects/module';
import organizationModule from '@modules/organizations/module';
import memberModule from '@modules/member/module';
import accountModule from '@modules/account/module';
import orderBy from 'lodash/orderBy';
import { FiMapPin } from 'react-icons/fi';
import { ORGANIZATION_TYPE_BGCOLORS, ORGANIZATION_TYPE_COLORS } from '@theme';

export const RELATION_FETCH_LIMIT = 10;

export const ENTITY_SELECT_SEARCH_LIMIT = 30;

export const DEFAULT_PROJECT_TAG_ROOT = 'energy-storage-type';

export const DEFAULT_ORGANIZATION_TAG_ROOT = 'sector';

export const MAP_EMPTY_ID_COLLECTION = ['0'];

export const gtmConfig = {
  containerId: process.env.NEXT_PUBLIC_GTM_CONTAINER_ID,
};

export const languages = [
  {
    lang: 'de',
    label: 'Deutsch',
    icon: '/static/icons/de_flag.svg',
  },
  {
    lang: 'en',
    label: 'English',
    icon: '/static/icons/uk_flag.svg',
  },
];

export const routes = {
  default: '/',
  defaultLoggedIn: '/member',
  auth: {
    login: '/auth/login',
  },
};

export const modules = [
  projectsModule,
  organizationModule,
  memberModule,
  accountModule,
];

export function getSidebarSections(t, router, sort = []) {
  return [
    ...orderBy(modules.flatMap((module) => module.menu?.getSections(t, router) ?? []), sort),
  ];
}

export const acceptedImageFileTypes = {
  'image/*': ['.png', '.gif', '.jpeg', '.jpg', '.svg', '.webp'],
};

export const ORGANIZATION_TYPES = [
  {
    value: 'researchInstitution',
    label: 'Forschungseinrichtung',
    bgColor: ORGANIZATION_TYPE_BGCOLORS.researchInstitution,
    textColor: ORGANIZATION_TYPE_COLORS.researchInstitution,
  },
  {
    value: 'instituteCentralFacilityChair',
    label: 'Institut / zentrale Einrichtung / Lehrstuhl',
    bgColor: ORGANIZATION_TYPE_BGCOLORS.instituteCentralFacilityChair,
    textColor: ORGANIZATION_TYPE_COLORS.instituteCentralFacilityChair,
    level: 1,
  },
  {
    value: 'subgroup',
    label: 'Subgroup / Abteilung',
    bgColor: ORGANIZATION_TYPE_BGCOLORS.subgroup,
    level: 2,
  },
  {
    value: 'company',
    label: 'Unternehmen',
    bgColor: ORGANIZATION_TYPE_BGCOLORS.company,
    textColor: ORGANIZATION_TYPE_COLORS.company,
  },
  {
    value: 'location',
    label: 'Standort',
    bgColor: ORGANIZATION_TYPE_BGCOLORS.location,
    textColor: ORGANIZATION_TYPE_COLORS.location,
    level: 1,
  },
  {
    value: 'department',
    label: 'Abteilung',
    bgColor: ORGANIZATION_TYPE_BGCOLORS.department,
    textColor: ORGANIZATION_TYPE_COLORS.department,
    level: 2,
  },
  {
    value: 'federalMinistry',
    label: 'Ministerium',
    bgColor: ORGANIZATION_TYPE_BGCOLORS.federalMinistry,
    textColor: ORGANIZATION_TYPE_COLORS.federalMinistry,
  },
  {
    value: 'referat',
    label: 'Referat',
    bgColor: ORGANIZATION_TYPE_BGCOLORS.referat,
    level: 1,
  },
  {
    value: 'projectSponsor',
    label: 'Projektträger',
    bgColor: ORGANIZATION_TYPE_BGCOLORS.projectSponsor,
    textColor: ORGANIZATION_TYPE_COLORS.projectSponsor,
  },
  {
    value: 'workUnit',
    label: 'Arbeitseinheit',
    bgColor: ORGANIZATION_TYPE_BGCOLORS.workUnit,
    level: 1,
  },
  {
    value: 'press',
    label: 'Presse',
    bgColor: ORGANIZATION_TYPE_BGCOLORS.press,
    textColor: ORGANIZATION_TYPE_COLORS.press,
  },
];

export const MAIN_MENU_CONTENT_ITEMS = {
  primary: [
    {
      id: 'organizations',
      label: 'links.organizations.label',
      path: 'links.organizations.url',
      variant: 'text',
      icon: <FiMapPin />,
      acl: [],
    },
    {
      id: 'projects',
      label: 'links.projects.label',
      path: 'links.projects.url',
      variant: 'text',
      icon: <FiMapPin />,
      acl: [],
    },
    {
      id: 'otherProjects',
      label: 'links.otherProjects.label',
      path: 'links.otherProjects.url',
      variant: 'text',
      icon: null,
      acl: [],
      type: 'content',
    },
    {
      id: 'participation',
      label: 'links.participation.label',
      path: 'links.participation.url',
      variant: 'text',
      icon: null,
      acl: [],
      type: 'content',
    },
    {
      id: 'info',
      label: 'links.info.label',
      path: 'links.info.url',
      variant: 'text',
      icon: null,
      acl: [],
      type: 'content',
    },
    {
      id: 'about-us',
      label: 'links.about-us.label',
      path: 'links.about-us.url',
      acl: [],
      type: 'content',
    },
    {
      id: 'beta',
      isBeta: true,
      label: 'links.beta.label',
      path: 'links.beta.url',
      acl: [],
      type: 'content',
    },
  ],
  secondary: [
    {
      id: 'privacy-policy',
      label: 'links.privacy-policy.label',
      path: 'links.privacy-policy.url',
      acl: [],
      type: 'content',
    },
    {
      id: 'imprint',
      label: 'links.imprint.label',
      path: 'links.imprint.url',
      acl: [],
      type: 'content',
    },
  ],
};
