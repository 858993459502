import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import merge from 'lodash/merge';
import en from './en.json';
import de from './de.json';
import { modules } from '../../config';

const resources = {
  en,
  de,
};

modules
  .filter((module) => Boolean(module.i18n?.resources))
  .forEach((module) => {
    merge(resources, module.i18n.resources);
  });

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'de',
    supportedLngs: Object.keys(resources),
    interpolation: {
      escapeValue: false,
    },
  });

export const languages = Object.keys(resources);
